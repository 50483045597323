import {NavigationService} from '../services/NavigationService';
import {StyleSettingsService} from '../services/StyleSettingsService';
import {CheckoutNavigationService, ModalType, SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {CartService} from '../services/CartService';
import {StoreMetaDataService} from '../services/StoreMetaDataService';
import {CreateCheckoutExceptions, FedopsInteractions, OriginTypes} from '../../common/constants';
import {clickOnCheckoutParams} from '@wix/bi-logger-ecom-platform-data/v2/types';
import {OrderService} from '../services/OrderService';
import {MinimumOrderAmountService} from '../services/MinimumOrderAmountService';
import {
  getAdditionalFeesPrice,
  getCatalogAppIds,
  getNumberOfAdditionalFees,
  getOriginalShippingMethod,
  getShippingMethodType,
  getValidations,
} from '../utils/bi.utils';
import {openNoOnlinePaymentsModal} from '../utils/openNoOnlinePaymentsModal';
import {ICartControllerApi} from '../../types/app.types';

export class NavigationStore {
  private readonly navigationService: NavigationService;
  private readonly styleSettingsService: StyleSettingsService;
  private readonly checkoutNavigationService: CheckoutNavigationService;
  private readonly cartService: CartService;
  private readonly orderService: OrderService;
  private readonly storeMetaDataService: StoreMetaDataService;
  private readonly minimumOrderAmountService: MinimumOrderAmountService;
  private readonly controllerApi: ICartControllerApi;
  private readonly origin: string;

  constructor(
    controllerApi: ICartControllerApi,
    private readonly siteStore: SiteStore,
    {
      navigationService,
      styleSettingsService,
      checkoutNavigationService,
      cartService,
      storeMetaDataService,
      orderService,
      minimumOrderAmountService,
    }: {
      navigationService: NavigationService;
      styleSettingsService: StyleSettingsService;
      checkoutNavigationService: CheckoutNavigationService;
      cartService: CartService;
      storeMetaDataService: StoreMetaDataService;
      orderService: OrderService;
      minimumOrderAmountService: MinimumOrderAmountService;
    },
    {origin}: {origin: string}
  ) {
    this.navigationService = navigationService;
    this.styleSettingsService = styleSettingsService;
    this.cartService = cartService;
    this.orderService = orderService;
    this.checkoutNavigationService = checkoutNavigationService;
    this.storeMetaDataService = storeMetaDataService;
    this.minimumOrderAmountService = minimumOrderAmountService;
    this.controllerApi = controllerApi;
    this.origin = origin;
  }

  private async handleCheckoutNotAllowed({modalType}: {modalType: ModalType}): Promise<boolean> {
    const cartModel = this.cartService.cartModel;
    const estimatedTotals = this.cartService.estimatedTotals;
    const destination = cartModel.contactInfo?.address;
    const catalogAppId = getCatalogAppIds(cartModel);
    const additionalFeesPrice = getAdditionalFeesPrice(estimatedTotals);
    const additionalFeesNumber = getNumberOfAdditionalFees(estimatedTotals);
    const response = await this.checkoutNavigationService.openLegacyCartModal(
      modalType,
      {destination, catalogAppId, additionalFeesPrice, additionalFeesNumber},
      this.styleSettingsService.isEditorX
    );

    return modalType === ModalType.UpgradeToPremium && response?.proceed;
  }

  private readonly sendClickOnCheckoutBI = async ({
    activePaymentMethods,
    isPickupFlow,
  }: {
    activePaymentMethods: {
      name: string;
      type: string;
    }[];
    isPickupFlow?: boolean;
  }) => {
    const BIData: clickOnCheckoutParams = {
      origin: this.origin,
      cartId: this.cartService.cartModel.id,
      itemsCount: this.cartService.itemsCount,
      cartType: this.cartService.cartType,
      productsList: JSON.stringify(
        this.cartService.cartModel.lineItems.map((lineItem) => ({
          id: lineItem.catalogReference.catalogItemId,
          quantity: lineItem.quantity,
        }))
      ),
      num_of_paymet_providers: activePaymentMethods.length,
      is_with_ewallet_payment: await this.storeMetaDataService.hasEWalletPaymentMethods(),
      is_with_offline_payment: await this.storeMetaDataService.hasOfflinePaymentMethods(),
      paymet_providers: (await this.storeMetaDataService.getPaymentMethodsNames()).join(','),
      orig_shipping_method: getOriginalShippingMethod(this.cartService.estimatedTotals),
      shippingMethodType: getShippingMethodType(this.cartService.isNonShippableCart, isPickupFlow),
      additionalFeesPrice: getAdditionalFeesPrice(this.cartService.estimatedTotals),
      numberOfAdditionalFees: getNumberOfAdditionalFees(this.cartService.estimatedTotals),
      num_of_shipping: this.cartService.estimatedTotals?.shippingInfo?.shippingRule?.shippingOptions.length ?? 0,
    };
    void this.siteStore.platformBiLogger.clickOnCheckout({
      ...BIData,
      checkoutId: this.cartService.cartModel.checkoutId,
      purchaseFlowId: this.cartService.cartModel.purchaseFlowId,
      origin: this.origin,
      catalogAppId: getCatalogAppIds(this.cartService.cartModel),
      is_member: !!this.siteStore.usersApi.currentUser.loggedIn,
      validationMessage: getValidations(this.cartService.estimatedTotals?.violations),
    });
  };

  private async createCheckoutAndGetIsPreselectedFlow(): Promise<{
    createCheckoutResult: string | {error: string};
    isPreselectedFlow?: boolean;
  }> {
    const {shouldShowShipping} = this.styleSettingsService;
    const shouldCallEstimateTotals = !shouldShowShipping && !this.cartService.isNonShippableCart;

    const [createCheckoutResult, isPreselectedFlow] = await Promise.all([
      this.cartService.createCheckout(),
      shouldCallEstimateTotals
        ? this.cartService.isPreselectedNonShippingFlow()
        : Promise.resolve(this.orderService.isPickup() || this.orderService.hasTimeSlots()),
    ]);

    return {createCheckoutResult, isPreselectedFlow};
  }

  private async handleCreateCheckoutError(createCheckoutResult: {error: string}) {
    if (createCheckoutResult?.error === CreateCheckoutExceptions.siteMustAcceptPayments) {
      await openNoOnlinePaymentsModal(
        this.checkoutNavigationService,
        this.styleSettingsService.isEditorX,
        this.cartService.cartModel,
        this.cartService.estimatedTotals
      );
    } else {
      await this.cartService.handleGeneralError({
        name: createCheckoutResult.error,
        message: createCheckoutResult.error,
      });
    }
  }

  private readonly handleCheckoutButtonClick = async ({accessibilityEnabled}: {accessibilityEnabled: boolean}) => {
    await this.controllerApi.reportFedops(FedopsInteractions.CheckoutButtonClicked, async () => {
      this.navigationService.isNavigationToCheckoutInProcess = true;

      const {createCheckoutResult, isPreselectedFlow} = await this.createCheckoutAndGetIsPreselectedFlow();

      if (typeof createCheckoutResult !== 'string' && createCheckoutResult?.error) {
        await this.handleCreateCheckoutError(createCheckoutResult);
        this.navigationService.isNavigationToCheckoutInProcess = false;
        return;
      }
      const storeMetaData = await this.storeMetaDataService.get();
      const {hasCreatedPaymentMethods, activePaymentMethods, canStoreShip, isPremium} = {...storeMetaData};

      const checkIsAllowedToCheckoutParams = {
        hasShippableItems: this.cartService.hasShippableItems,
        isPremium,
        canStoreShip,
        hasCreatedPaymentMethods,
        canShipToDestination:
          this.orderService.canShip ||
          this.cartService.isFullAddressRequired ||
          !this.styleSettingsService.shouldShowShipping,
      };

      const {canCheckout, modalType} = this.checkoutNavigationService.checkIsAllowedToCheckout({
        ...checkIsAllowedToCheckoutParams,
        fullPaymentOffline:
          !!this.cartService.estimatedTotals && this.cartService.estimatedTotals.payNow.total.convertedAmount === 0,
      });

      if (!canCheckout) {
        const shouldProceed = await this.handleCheckoutNotAllowed({modalType});
        if (!shouldProceed) {
          return;
        }
      }

      const deviceType = this.siteStore.isDesktop() ? 'desktop' : /* istanbul ignore next */ 'mobile';

      await this.sendClickOnCheckoutBI({activePaymentMethods, isPickupFlow: this.orderService.isPickup()});

      await this.checkoutNavigationService.navigateToCheckout({
        a11y: accessibilityEnabled,
        cartId: this.cartService.cartModel.id,
        locale: this.siteStore.locale,
        deviceType,
        originType: OriginTypes.AddToCart,
        siteBaseUrl: this.siteStore.location.baseUrl,
        ...{isPreselectedFlow},
        ...(this.cartService.checkoutId ? {checkoutId: this.cartService.checkoutId} : {}),
      });
      this.navigationService.isNavigationToCheckoutInProcess = false;
    });
  };

  private get isCheckoutButtonDisabled() {
    return (
      this.navigationService.isNavigationToCheckoutInProcess ||
      !this.cartService.areAllItemsInStock ||
      this.minimumOrderAmountService.shouldDisableCheckout ||
      this.cartService.hasErrorViolations
    );
  }

  public async toProps() {
    return {
      isCheckoutButtonDisabled: this.isCheckoutButtonDisabled,
      locale: this.siteStore.locale,
      continueShopping: this.navigationService.continueShopping,
      continueShoppingHref: await this.navigationService.getContinueShoppingHref(),
      navigateToProduct: this.navigationService.navigateToProduct,
      navigateToFullProductUrl: this.navigationService.navigateToFullProductUrl,
      shouldRenderContinueShopping: this.styleSettingsService.shouldRenderContinueShopping,
      handleCheckoutButtonClick: this.handleCheckoutButtonClick,
      navigateToCart: this.navigationService.navigateToCart,
      closeSideCart: this.navigationService.closeSideCart,
      isNavigationToCheckoutInProcess: this.navigationService.isNavigationToCheckoutInProcess,
    };
  }
}
